<template>
	<v-container>
		<v-row>
			<v-col cols="8" offset="2">
				<v-sheet min-height="70vh" class="px-12">
					<Post20231227></Post20231227>
				</v-sheet>

				<v-sheet class="mt-3" min-height="30vh">
					<v-list color="transparent">
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title
									class="text-h6 font-weight-light text-uppercase"
									>News
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-divider class="mt-1"></v-divider>

						<v-list-item-group color="primary">
							<v-list-item
								class="v-item--active v-list-item--active"
							>
								<v-list-item-content>
									<v-list-item-title class="text-button"
										>SFC 2024 Teams
										Announced</v-list-item-title
									>
									<v-list-item-subtitle class="text-caption"
										>2023-12-27</v-list-item-subtitle
									>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-sheet>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Post20231227 from "@/views/news/posts/2023-12-27.vue";

export default {
	components: {
		Post20231227,
	},

	data: () => ({}),
};
</script>